<template>
  <section class="success-wrapper">
    <div class="icon" />
    <div class="thanks">
      Payment success. Thank you!
    </div>
  </section>
</template>

<script>
import {
  paypalOrderSuccess
} from '@/utils'
export default {
  created () {
    setTimeout(() => {
      paypalOrderSuccess({})
    }, 3000)
  }
}
</script>

<style lang="less" scoped>
  .success-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    .icon {
      width: 180px;
      height: 180px;
      background-image: url('~@/assets/img/success-icon.png');
      background-size: cover;
      margin-bottom: 60px;
    }
    .thanks {
      color: #999999;
    }
  }
</style>
